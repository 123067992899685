import React, { useEffect } from "react";
import Swatch from "./Swatch";
import ColorSlider from "./ColorSlider";
import inStockImg from "./media/instock.png";

export default function ProductLoop({ products, rows }) {
  const classes =
    rows == 4 ? "col-lg-3 col-md-3 col-sm-6" : "col-lg-4 col-md-4 col-sm-6";
  const product = products.length > 0 ? products[0] : null;
  const siteInfo = window.wpProductFilter.siteInfo;
  const getcouponbtn = siteInfo.getcouponbtn;
  const getcouponreplace = siteInfo.getcouponreplace;
  const getcouponreplacetext = siteInfo.getcouponreplacetext;
  const getcouponreplaceurl = siteInfo.getcouponreplaceurl;
  const show_financing = siteInfo.show_financing;
  const getfinancereplace = siteInfo.getfinancereplace;
  const getcoupon_link = siteInfo.getcoupon_link;
  const showprice = siteInfo.plpshowprice;
  const plpestimatebutton = siteInfo.plpestimatebutton;
  const plpestimatebuttonlink = siteInfo.plpestimatebuttonlink;

  const getfinancereplaceurl =
    getfinancereplace === "1"
      ? siteInfo.getfinancereplaceurl
      : "/flooring-financing/";
  const getfinancetext =
    getfinancereplace === "1" ? siteInfo.getfinancetext : "Get Financing";
  const wpProductCategory = window.wpProductCategory;
  let url_slug = product.url_key;
  if (
    wpProductCategory.rewrite_slug !== "" &&
    wpProductCategory.rewrite_slug !== undefined
  ) {
    const segments = url_slug.split("/");
    url_slug =
      "/" +
      wpProductCategory.rewrite_slug +
      "/" +
      segments[segments.length - 1];
  }

  return (
    <div className={`product-item ${classes}`}>
      <div className="fl-post-grid-post">
        <div className="fl-post-grid-image">
          <Swatch
            swatch={product.swatch}
            pdpUrl={`${window.location.origin}${url_slug}`}
            height={222}
            width={222}
          />
          {product.in_stock == 1 && (
            <img
              src={inStockImg}
              className="instockImgPlp entered"
              alt="In Stock"
            />
          )}
        </div>
        <div className="fl-post-grid-text product-grid btn-grey">
          <h4>
            <span>{product.collection_facet}</span>
            <span>{product.brand}</span>
          </h4>

          <div className="product-variations1">
            <h5>{`${products?.length} COLORS AVAILABLE`}</h5>
            <div className="product-color-variationPlp plp_slide_wrap">
              <ColorSlider products={products} page={"plp"} />
            </div>
          </div>

          {product.in_stock == 1 && showprice == 1 && product.price != 0 && (
            <div className="price">
              <strong>
                <sup>$</sup>
                {product.price}
              </strong>
              &nbsp;/<sub>{product.price_unit}</sub>
            </div>
          )}
          <a
            href={`${window.location.origin}${url_slug}`}
            className="fl-button plp_box_btn">
            View Product
          </a>

          {getcouponbtn === "1" ? (
            <a href={getcoupon_link} className="link getcouponbtnlink">
              <span className="fl-button-text">GET COUPON</span>
            </a>
          ) : (
            ""
          )}
          {product.in_stock === 1 && plpestimatebutton === "1" ? (
            <a
              href={`${plpestimatebuttonlink}?ChoiceColor=${product.color_facet}&amp;Choicesku=${product.sku}&amp;Choicecollection=${product.collection_facet}&amp;Flooringtype=${product.z_prod_type}`}
              target="_self"
              className="link plpestimatelink"
              role="button">
              <span class="fl-button-text">REQUEST AN ESTIMATE</span>
            </a>
          ) : (
            ""
          )}
          {getcouponreplace === "1" &&
          getcouponreplaceurl !== "" &&
          getcouponreplacetext !== "" ? (
            <a
              href={getcouponreplaceurl}
              className="linkcustompdpbtnlink  d-block">
              <span className="fl-button-text">{getcouponreplacetext}</span>
            </a>
          ) : (
            ""
          )}

          {show_financing === "1" ? (
            <a href={getfinancereplaceurl} className="link d-block">
              <span class="fl-button-text">{getfinancetext}</span>
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
