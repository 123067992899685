import React from "react";
// import { Link } from "react-router-dom";

export default function SwatchImagesPDP({
  swatch,
  populatePopup,
  width,
  height,
}) {
  let swatchimg = `https://mm-media-res.cloudinary.com/image/fetch/h_${height},w_${width},c_fill/https://${swatch}`;
  return (
    <div id="product-images-holder">
      <div
        class="img-responsive toggle-image"
        //style={{swatchbg}}
        style={{
          backgroundImage: `url(${swatchimg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        data-targetimg="gallery_item_0"
        data-responsive={swatch}
        data-src={swatch}
        data-exthumbimage={swatchimg}>
        <a
          href="javascript:void(0)"
          onClick={(e) => populatePopup("show")}
          class="popup-overlay-link"></a>
        <span class="main-imgs">
          <img src={swatchimg} class="img-responsive toggle-image" />
        </span>
        {/* <a
      href={`${pdpUrl}`} // Use Link for client-side navigation
    >
      <img
        src={swatchimg}
        width={width}
        height={height}
      />
    </a> */}
      </div>
    </div>
  );
}
