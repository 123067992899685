import React from "react";

export default function Pagination({ number, handlePagination, currentPage }) {
  var active_class = Number(currentPage) == number ? "active" : "";
  return (
    <li key={number} className={active_class}>
      <button onClick={handlePagination} value={number}>
        {number}
      </button>
    </li>
  );
}
