import React from "react";

export default function BrandFacet({ handleFilterClick, productBrands }) {
  return (
    <div class="facet-wrap facet-display">
      <strong>Brand</strong>
      <div className="facetwp-facet">
        {/* <select
          onChange={(e) => handleFilterChange("brand_facet", e.target.value)}>
           <option value="">All Brands</option>
          {Object.keys(productBrands).map((brand, i) => {
            if (productBrands[brand] > 0) {
              return (
                <option key={i} value={`${brand}`}>
                  {brand} {` (${productBrands[brand]}) `}
                </option>
              );
            }
          })}
        </select> */}
        {Object.keys(productBrands).map((brand, i) => {
          if (brand && productBrands[brand] > 0) {
            return (
              <div>
                <span
                  id={`brand-filter-${i}`}
                  key={i}
                  data-value={`${brand}`}
                  onClick={(e) =>
                    handleFilterClick("brand_facet", e.target.dataset.value)
                  }>
                  {" "}
                  {brand} {` (${productBrands[brand]}) `}
                </span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
