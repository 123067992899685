import React from "react";

export default function WidthFacet({ handleFilterClick, productWidth }) {
  return (
    <div class="facet-wrap facet-display">
      <strong>Width</strong>
      <div className="facetwp-facet">
        {Object.keys(productWidth).map((width, i) => {
          if (width && productWidth[width] > 0) {
            return (
              <div>
                <span
                  id={`width-filter-${i}`}
                  key={i}
                  data-value={`${width}`}
                  onClick={(e) =>
                    handleFilterClick("width", e.target.dataset.value)
                  }>
                  {" "}
                  {width} {` (${productWidth[width]}) `}
                </span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
