import React from "react";
import Swatch from "./Swatch";

export default function ColorSliderElementPdp({ product, currentProductSku }) {
  var status = product.sku === currentProductSku ? "active" : "";
  const wpProductCategory = window.wpProductCategory;
  let url_slug = product.url_key;

  if (
    wpProductCategory.rewrite_slug !== "" &&
    wpProductCategory.rewrite_slug !== undefined
  ) {
    const segments = url_slug.split("/");
    url_slug =
      "/" +
      wpProductCategory.rewrite_slug +
      "/" +
      segments[segments.length - 1];
  }

  return (
    <div className="slide color-box">
      <figure class="color-boxs-inner">
        <div class={`color-boxs-inners ${status}`}>
          <Swatch
            swatch={product.swatch}
            pdpUrl={`${window.location.origin}${url_slug}`}
            height={100}
            width={100}
          />
          <a href={`${window.location.origin}${url_slug}`}>
            <small>{product.color}</small>
          </a>
        </div>
      </figure>
    </div>
  );
}
