import React from "react";

export default function InstallationMethodFacet({
  handleFilterClick,
  productInstallationMethods,
}) {
  return (
    <div class="facet-wrap facet-display">
      <strong>Installation Method</strong>
      <div className="facetwp-facet">
        {Object.keys(productInstallationMethods).map((installation, i) => {
          if (installation && productInstallationMethods[installation] > 0) {
            return (
              <div>
                <span
                  id={`installation-filter-${i}`}
                  key={i}
                  data-value={`${installation}`}
                  onClick={(e) =>
                    handleFilterClick(
                      "installation_facet",
                      e.target.dataset.value
                    )
                  }>
                  {" "}
                  {installation}{" "}
                  {` (${productInstallationMethods[installation]}) `}
                </span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
