import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Loader = () => {
  return (
    <div className="skeleton-wrapper">
      <Skeleton height={300} width={200} className="skeleton-item" />
      <Skeleton height={300} width={200} className="skeleton-item" />
      <Skeleton height={300} width={200} className="skeleton-item" />
      <Skeleton height={300} width={200} className="skeleton-item" />
    </div>
  );
};

export default Loader;
