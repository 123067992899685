import React, { useEffect } from "react";
import Swatch from "./Swatch";

export default function ProductLoopSearch({ product }) {
  return (
    <div className="product-item col-lg-4 col-md-4 col-sm-6 ">
      <div className="fl-post-grid-post">
        <div className="fl-post-grid-image">
          <Swatch
            swatch={product.swatch}
            pdpUrl={product.url_key}
            height={222}
            width={222}
          />
        </div>
        <div className="fl-post-grid-text product-grid btn-grey">
          <h4>
            <span>{product.collection_facet}</span>
            <span>{product.brand}</span>
          </h4>

          {/* <div className="product-variations1">
            <h5>{`${products?.length} COLORS AVAILABLE`}</h5>
            <div className="product-color-variationPlp plp_slide_wrap">
              <ColorSlider products={products} page={"plp"} />
            </div>
          </div> */}
          <a
            href={`${window.location.origin}${product.url_key}`}
            className="fl-button plp_box_btn">
            View Product
          </a>
        </div>
      </div>
    </div>
  );
}
