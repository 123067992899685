import React from "react";

export default function PerPage({ handlePerPage }) {
  return (
    <div class="facetwp-per-page">
      <select
        class="facetwp-per-page-select"
        onChange={(e) => handlePerPage(e.target.value)}>
        <option value="12">Per page</option>
        <option value="12">12</option>
        <option value="24">24</option>
        <option value="36">36</option>
        <option value="48">48</option>
        <option value="60">60</option>
      </select>
    </div>
  );
}
