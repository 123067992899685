import React from "react";

export default function ShadeFacet({ handleFilterClick, productShade }) {
  return (
    <div class="facet-wrap facet-display">
      <strong>Shade</strong>
      <div className="facetwp-facet">
        {Object.keys(productShade).map((shade, i) => {
          if (shade && productShade[shade] > 0) {
            return (
              <div>
                <span
                  id={`shade-filter-${i}`}
                  key={i}
                  data-value={`${shade}`}
                  onClick={(e) =>
                    handleFilterClick("shade_facet", e.target.dataset.value)
                  }>
                  {" "}
                  {shade} {` (${productShade[shade]}) `}
                </span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
