import React from "react";

function RemoveFilters({ filters, handleRemoveFilter }) {
  var filterNames = {
    search: "Search",
    color_facet: "Color",
    brand_facet: "Brand",
    collection_facet: "Collection",
    application_facet: "Application",
    color_variation_facet: "Variation",
    construction_facet: "Construction",
    installation_facet: "Installation",
    location_facet: "Location",
    species_facet: "Species",
    surface_texture_facet: "Texture",
    shade_facet: "Shade",
    shape_facet: "Shape",
    look_facet: "Look",
    thickness_facet: "Thickness",
    height: "Height",
    width: "Width",
    sample_available_facet: "Sample Available",
    style_facet: "Style",
    surface_texture_facet: "Texture",
    limit: "Per Page",
  };

  return (
    <div className="facetwp-selections">
      <ul>
        {Object.keys(filters).map((filter, index) => {
          return (
            filters[filter] &&
            filters[filter] != "limit" && (
              <li
                key={index}
                data-facet={filters[filter]}
                className="activeFilters1">
                <span className="facetwp-selection-label">
                  {`${filterNames[filter]} :`}
                </span>
                <span
                  className="facetwp-selection-value"
                  onClick={(e) => {
                    handleRemoveFilter(filter);
                  }}>
                  {filters[filter]}
                  <span className="remove-filter-icon">X</span>
                </span>
              </li>
            )
          );
        })}
      </ul>
    </div>
  );
}

export default RemoveFilters;
