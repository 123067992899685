import React from "react";
import Swatch from "./Swatch";

export default function ColorSliderElement({ count, product, page }) {
  const maxProduct = 5;
  const wpProductCategory = window.wpProductCategory;
  let url_slug = product.url_key;
  if (
    wpProductCategory.rewrite_slug !== "" &&
    wpProductCategory.rewrite_slug !== undefined
  ) {
    const segments = url_slug.split("/");
    url_slug =
      "/" +
      wpProductCategory.rewrite_slug +
      "/" +
      segments[segments.length - 1];
  }
  return (
    <div className="slide color-box1 ">
      {page === "plp" ? (
        count === maxProduct ? (
          "+"
        ) : (
          <a href={`${window.location.origin}${url_slug}`}>
            <Swatch
              swatch={product.swatch}
              pdpUrl={`${window.location.origin}${url_slug}`}
              height={50}
              width={50}
            />
          </a>
        )
      ) : (
        <a href={`${window.location.origin}${url_slug}`}>
          <Swatch
            swatch={product.swatch}
            pdpUrl={`${window.location.origin}${url_slug}`}
            height={100}
            width={100}
          />
        </a>
      )}
    </div>
  );
}
