import React from "react";

export default function LocationFacet({ handleFilterClick, productLocations }) {
  return (
    <div class="facet-wrap facet-display">
      <strong>Location</strong>
      <div className="facetwp-facet">
        {Object.keys(productLocations).map((location, i) => {
          if (location && productLocations[location] > 0) {
            return (
              <div>
                <span
                  id={`location-filter-${i}`}
                  key={i}
                  data-value={`${location}`}
                  onClick={(e) =>
                    handleFilterClick("location_facet", e.target.dataset.value)
                  }>
                  {" "}
                  {location} {` (${productLocations[location]}) `}
                </span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
