import React from "react";

export default function ThicknessFacet({
  handleFilterClick,
  productThickness,
}) {
  return (
    <div class="facet-wrap facet-display">
      <strong>Thickness</strong>
      <div className="facetwp-facet">
        {Object.keys(productThickness).map((thickness, i) => {
          if (thickness && productThickness[thickness] > 0) {
            return (
              <div>
                <span
                  id={`thickness-filter-${i}`}
                  key={i}
                  data-value={`${thickness}`}
                  onClick={(e) =>
                    handleFilterClick("thickness_facet", e.target.dataset.value)
                  }>
                  {" "}
                  {thickness} {` (${productThickness[thickness]}) `}
                </span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
