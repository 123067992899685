import React from "react";

export default function CollectionFacet({
  handleFilterClick,
  productCollections,
}) {
  return (
    <div class="facet-wrap facet-display">
      <strong>Collection</strong>
      <div className="facetwp-facet">
        {Object.keys(productCollections).map((collection, i) => {
          if (collection && productCollections[collection] > 0) {
            return (
              <div>
                <span
                  id={`collection-filter-${i}`}
                  key={i}
                  data-value={`${collection}`}
                  onClick={(e) =>
                    handleFilterClick(
                      "collection_facet",
                      e.target.dataset.value
                    )
                  }>
                  {" "}
                  {collection} {` (${productCollections[collection]}) `}
                </span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
