import React from "react";
import ColorSliderElement from "./ColorSliderElement";

export default function ColorSlider({ products, page }) {
  // console.log(products);
  return (
    <div class="slides row">
      {products ?.map((product, index) => {
        return (

          page === "plp" ? (index < 6 && (
            <ColorSliderElement key={index} count={index} product={product} page={page} />
          )):(<ColorSliderElement key={index} count={index} product={product} page={page} />)

        );
      })}

    </div>
    
  );
}
