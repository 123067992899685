import React from "react";
import GalleryImages from "./GalleryImages";

export default function ProductGallerySlider({ product }) {
  return (
    <div>
      {product.gallery_images && (
        <GalleryImages
          swatch={product.swatch}
          gallery_images={product.gallery_images}
          height={800}
          width={800}
        />
      )}
    </div>
  );
}
