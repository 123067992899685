import React from "react";

export default function TextureFacet({ handleFilterClick, productTextures }) {
  return (
    <div class="facet-wrap  facet-display">
      <strong>Texture</strong>
      <div className="facetwp-facet facet-display">
        {Object.keys(productTextures).map((texture, i) => {
          if (texture && productTextures[texture] > 0) {
            return (
              <div>
                <span
                  id={`surface-texture-filter-${i}`}
                  key={i}
                  data-value={`${texture}`}
                  onClick={(e) =>
                    handleFilterClick(
                      "surface_texture_facet",
                      e.target.dataset.value
                    )
                  }>
                  {" "}
                  {texture} {` (${productTextures[texture]}) `}
                </span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
