import React from "react";

function ProductBrandLogos({ product }) {
  return (
    <div>
      {product.brand_facet == "Shaw Floors" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\shaw_logo.jpg"
          alt="Shaw Logo"
        />
      ) : product.brand_facet == "Anderson Tuftex" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\anderson_tuftex_logo.jpg"
          alt="Anderson Tuftex"
        />
      ) : product.brand_facet == "Karastan" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\karastan_logo.jpg"
          alt="Karastan"
        />
      ) : product.brand_facet == "Armstrong" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\armstrong_logo.jpg"
          alt="Armstrong"
        />
      ) : product.brand_facet == "Dreamweaver" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\dreamweaver_logo.jpg"
          alt="Dream Weaver"
        />
      ) : product.brand_facet == "Philadelphia Commercial" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\philadelphia_commercial_logo.jpg"
          alt="Philadelphia Commercial"
        />
      ) : product.brand_facet == "COREtec" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\coretec_logo.jpg"
          alt="COREtec"
        />
      ) : product.brand_facet == "Daltile" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\daltile_logo.jpg"
          alt="Daltile"
        />
      ) : product.brand_facet == "Mohawk" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\mohawk_logo.jpg"
          alt="Mohawk"
        />
      ) : product.brand_facet == "Bruce" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\bruce_logo.jpg"
          alt="Bruce"
        />
      ) : product.brand_facet == "American Olean" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\amrican_olean_logo.jpg"
          alt="American Olean"
        />
      ) : product.brand_facet == "Mannington" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\mannington_logo.jpg"
          alt="Mannington"
        />
      ) : product.brand_facet == "Fabrica" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\fabrica.jpg"
          alt="Fabrica"
        />
      ) : product.brand_facet == "Masland" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\masland.jpg"
          alt="Masland"
        />
      ) : product.brand_facet == "Dixie Home" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\dixie-home.jpg"
          alt="Dixie Home"
        />
      ) : product.brand_facet == "Nourison" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\nourison_logo.jpg"
          alt="Nourison"
        />
      ) : product.brand_facet == "Hydraluxe" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\hydraluxe_logo.jpg"
          alt="Hydraluxe"
        />
      ) : product.brand_facet == "Karndean" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\karndean_logo.jpg"
          alt="Karndean"
        />
      ) : product.brand_facet == "Cali" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\cali_logo.jpg"
          alt="Cali"
        />
      ) : product.brand_facet == "Robbins" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\robbins.jpg"
          alt="Robbins"
        />
      ) : product.brand_facet == "Mercier" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\mercier.jpg"
          alt="Mercier"
        />
      ) : product.brand_facet == "Hallmark" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\hallmark.jpg"
          alt="Hallmark"
        />
      ) : product.brand_facet == "Bella Cera" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\bella_cera.jpg"
          alt="Bella Cera"
        />
      ) : product.brand_facet == "Godfrey Hirst" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\godfrey_hirst.jpg"
          alt="Godfrey Hirst"
        />
      ) : product.brand_facet == "Stanton" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\stanton.jpg"
          alt="Stanton"
        />
      ) : product.brand_facet == "Emser" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\emsertile_logo.jpg"
          alt="Emser"
        />
      ) : product.brand_facet == "Marazzi" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\marazzi.jpg"
          alt="Marazzi"
        />
      ) : product.brand_facet == "Happy Feet" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\happy_feet.jpg"
          alt="Happy Feet"
        />
      ) : product.brand_facet == "Happy Floors" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\happy_floors.jpg"
          alt="Happy Floors"
        />
      ) : product.brand_facet == "Somerset" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\somerset.jpg"
          alt="Somerset"
        />
      ) : product.brand_facet == "Naturally Aged Flooring" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\naturally_aged_flooring.jpg"
          alt="Naturally Aged Flooring"
        />
      ) : product.brand_facet == "MSI Tile" ? (
        <img
          src="\wp-content\plugins\mm-product-listing\src\components\BrandLogo\msi_tile.jpg"
          alt="MSI Tile"
        />
      ) : (
        <p>{product.brand_facet}</p>
      )}
    </div>
  );
}

export default ProductBrandLogos;
