import React, { useState, useEffect } from "react";
import ProductLoopSearch from "./components/ProductLoopSearch";
import SearchPagination from "./components/SearchPagination";
import "./Products.css";

function SearchProduct() {
  const [products, setProducts] = useState([]);
  // const [totalProducts, setTotalProducts] = useState(0);
  const queryParams = new URLSearchParams(window.location.search);
  const search = queryParams.get("s") ? queryParams.get("s") : "";
  const wpProductFilter = window.wpProductFilter;
  const [paged, setPage] = useState(1);
  const [totalPages, settotalPages] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchProducts();
  }, [paged]);

  const handlePagination = (pageNo) => {
    setPage(pageNo.target.value);
  };

  const fetchProducts = async () => {
    const query = new URLSearchParams({
      category: "all",
      paged,
      limit: 12,
      search: search,
      globalSearch: true,
    });

    try {
      const response = await fetch(`${wpProductFilter.apiEndpoint}?${query}`);
      const data = await response.json();
      setProducts(data.products_group_by);
      settotalPages(data.total_pages);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };
  // console.log(products);
  return (
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <h2 className="search_category_title">Products</h2>
        <div className="product-plp-grid product-grid swatch row">
          {products.length > 0 &&
            products.map((product, i) => {
              return <ProductLoopSearch key={i} product={product} />;
            })}
          {products.length == 0 && loading == false && (
            <h2>No result found for products</h2>
          )}
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="page-info">
          <div className="pagination_wrapper">
            <div className="search_prev_link">
              {totalPages > 1 && paged > 1 && (
                <SearchPagination
                  title="LOAD PREVIOUS"
                  value={paged - 1}
                  handlePagination={handlePagination}
                />
              )}
            </div>
            <div className="search_next_link">
              {totalPages > 1 && paged < totalPages && (
                <SearchPagination
                  title="MORE RESULTS"
                  value={Number(paged) + 1}
                  handlePagination={handlePagination}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchProduct;
